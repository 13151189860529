<script>

import bnvform      from '@/vue-model-mirror/bnvform';
import draggable    from 'vuedraggable';

import card_left    from './card_left';
import column_right from './column_right';  //Colonna di destra che contiene il secondo draggable
//import { photoeditor_service } from '@/services';

const schemas = require('@/schemaconfig');
const config  = require('@/config.js')[ process.env.NODE_ENV || 'local' ];



//const sortByName = ( first, second, order ) => {
//    return order == 'asc' ? first.name < second.name : first.name > second.name;
//}
//
//const sortByDate = ( first, second, order ) => {
//    const date1 = new Date(first);
//    const date2 = new Date(second);
//
//    if (isNaN(date1) || isNaN(date2)) {
//      return false;
//    }
//
//    return order == 'asc' ?  date1 < date2 : date1 > date2 ;
//}

export default {
    name: 'pratica-photoeditor',
    props: [ 'codice_pratica', ],
    components: {
        draggable,
        bnvform,
        'card-left'    : card_left,
        'column-right' : column_right,
    },
    watch: {
        folder_found(new_val, old_val) {
            if ( new_val === true && old_val === null ) {
                var data = { options: {
                    parentId : this.store.photo_folder_id,
                    include  : true,
                }};
                this.$store.dispatch('photoeditor/get_all', data);
            } else if ( new_val === false && old_val === null ) {
                // cartella Foto non trovata
            }
        },
        gallery_loader(new_val, old_val) { // riportare sul parent
            if ( new_val === false && old_val === true ) {
                this.right_column = [];
                setTimeout( () => {
                    this.$bvToast.hide('gallery-toast');
                }, 5000);
            }
        },
        selectFolder(new_val, old_val) {
            if (new_val != old_val) {
                // Cerco il nuovo folder
                this.parentFodler = this.folders.find(el=> el.id === new_val)
                this.currentFolderLevel = this.parentFodler ? this.parentFodler.hierarchyLevel : 1;
                
                var data = { options: {
                    parentId :  this.currentFolderLevel == 1 ? this.store.photo_folder_id : this.parentFodler.id,
                    include  : true,
                }};
                this.$store.dispatch('photoeditor/get_all', data);
            }
        },
        report_created(new_val, old_val) {
           if (new_val != old_val && new_val) {
               this.$bvModal.msgBoxOk('Report memorizzato');
               this.$root.$emit('bv::toggle::modal', 'save-report');
           }
        },
        report_updated(new_val, old_val) {
            if (new_val != old_val && new_val) {
                this.$bvModal.msgBoxOk('Report aggiornato'); 
                this.$root.$emit('bv::toggle::modal', 'save-report');
            }
        },
    },
    created() {
        this.ed_schema = schemas.edit_description;
        this.$store.dispatch('photoeditor/set_codice_pratica', this.codice_pratica);
        this.$store.dispatch('photoeditor/get_photo_folder_id');
        this.$store.dispatch('pratica_reports/load', { options : { codice_pratica : this.codice_pratica, order :  'name ASC'}});
    },
    computed: {
        store() { return this.$store.state.photoeditor; },
        items: {
            get() {
                if ( this.$store.state.photoeditor.items === null ) { return []; }
                return this.$store.state.photoeditor.items;
            },
            //set( items ) { this.$store.dispatch('photoeditor/update_items', items); }
        },
        folder_found() { return this.$store.state.photoeditor.folder_found; },
        backend_url() { return config.backend_url },
        cloned_ids() { return this.right_column.map( x => x.id ); },
        
        // Prendo gli item che hanno la propieta' task_image valorizzata e che quindi sono immagini
//        images() { 
//            return Array.isArray( this.store.items ) ?
//                    this.store.items.filter( x => x.task_image != null && ['image/jpeg', 'image/png', 'image/bnp'].includes( x.task_image.value.mimetype ) && x.ancestors[0].hierarchyLevel == this.currentFolderLevel ) : [] 
//        },
        images() { 
            return Array.isArray( this.store.items ) ?
                    this.store.items.filter( x => x.task_image != null && ['image/jpeg', 'image/png', 'image/bnp'].includes( x.task_image.value.mimetype )  ) : [] 
        },
        // Prendo gli item che NON hanno la propieta' task_image valorizzata e che quindi sono cartelle
        folders() {
            if (this.foldersCache.length == 0) {
                this.foldersCache = [...[{ id: 0, name: "/" , hierarchyLevel : 1}], ...this.parseChilds( "", this.store.items )]; 
            }
//            return[...[{ id: 0, name: "/" , hierarchyLevel : 1}], ...this.parseChilds( "", this.store.items )];
            return this.foldersCache;
        },
        
        //images() { return this.store.items },
        gallery_message () {
            return this.$store.state.photoeditor.message;
        },
        gallery_variant () {
            return this.$store.state.photoeditor.variant;
        },
        gallery_loader () {
            return this.$store.state.photoeditor.loader;
        },
        report_created() {
            return this.$store.state.pratica_reports.created;
        },
        report_updated() {
            return this.$store.state.pratica_reports.updated;
        },
        reportList() {
            return Array.isArray( this.$store.state.pratica_reports.items ) ? this.$store.state.pratica_reports.items : [];
        },
        getRepportName() {
          return this.reportData.name == '' ? 'Report Nuovo' : this.reportData.name;   
        },
    },
    methods: {
        edit_description( file ) {
            this.ed_record.description = file.value.hasOwnProperty('description') ? file.value.description || null : null;
            this.ed_record.image_id    = file.id;
            this.toggle_modal();
        },
        reset_ed() {},
        submit_ed( ) {
            this.$refs.edit_description.submit_form();
            this.toggle_modal();
        },
        save_ed( data ) {
            var payload = { description: data.description || null, };
            var options = {
                image_id : data.image_id,
                params   : { include: true, },
                payload,
            };
            this.$store.dispatch('photoeditor/edit_description', options);
        },
        parseChilds( parentPath, childs) {
            let res = [];
            
            for (let i = 0; i < childs.length; i++) {
                let el = childs[i];
                if (el.task_image_id !== null) continue;
                
                res.push({ id: el.id, name: parentPath + "/" + el.name, hierarchyLevel : el.hierarchyLevel});
                if (el.children && el.children.length > 0) {
                    res = [...res, ...this.parseChilds( parentPath + "/" + el.name,  el.children) ];
                }
            }
            
            return res;
        },
        setSorting(mode) {
            this.sorting = mode;
        },
        filterImages( images ) {
          const params = this.sorting.split('-');
          
          
          if (!Array.isArray(this.store.items) || this.store.items.length === 0) {
              return []; // Restituisce un array vuoto se l'input non è valido
            }
          
          return  images.slice().sort((a, b) => {
              var valoreA = a.name;
              var valoreB = b.name;

              if (params[0] == 'date') {
                  valoreA = new Date( a.task_image.value.created );
                  valoreB = new Date( b.task_image.value.created );
              }
              
              if (valoreA < valoreB) {
                return params[1] === 'up' ? -1 : 1;
              }
              if (valoreA > valoreB) {
                return params[1] === 'up' ? 1 : -1;
              }
              return 0; // Se i valori sono uguali, mantiene l'ordine originale
            });
          
          
          return  this.store.items.slice().sort((a, b) => {
            return params[0] == 'name' ? sortByName(a,b,params[1] == 'down' ) : sortByDate(a,b,params[1] == 'down' );
          });
        },
        getSortingText() {
            if (this.sorting == 'data-up') return 'Data Upload';
            else if (this.sorting == 'data-down') return 'Data Upload';
            else if (this.sorting == 'name-up') return 'Nome file';
            else if (this.sorting == 'name-down') return 'Nome file';
        },
        getSortingOrder() {
            if (this.sorting == 'data-up') return 'arrow-up';
            else if (this.sorting == 'data-down') return 'arrow-down';
            else if (this.sorting == 'name-up') return 'arrow-up';
            else if (this.sorting == 'name-down') return 'arrow-down';
        },
        
        isCorrectFolder( image){
            if ( this.currentFolder == 1 || !this.parentFolder) {
                // In questo caso devo prendere l'immagine solo se 
                return image.parent.hierarchyLevel == 1
            }
            // Se no devo prendere solo le immagini che corrispondo al parent folder
            return image.parent.id == this.parentFolder.id
        },
        zoom(image) {
            this.zoomImage = image;
            this.toggle_modal('zoom');
        },
        async rotate(image) {
//            const img = new Image();
//            img.onload = function() {
//              const rotatedCanvas = photoeditor_service.rotateImage(img, 90); 
//              console.log(rotatedCanvas);
//              
//              const rotatedBlob = await new Promise(resolve => rotatedCanvas.toBlob(resolve, 'image/jpeg')); 
//
//              const formData = new FormData();
//              formData.append('rotatedImage', rotatedBlob. image.task_image.id); 
////
////              try {
////                const response = await fetch(uploadUrl, {
////                  method: 'POST',
////                  body: formData,
////                });
////
////                if (!response.ok) {
////                  throw new Error(`HTTP error! status: ${response.status}`);
////                }
////
////                const result = await response.json(); // Or response.text() if your backend returns plain text
////                console.log('Image uploaded successfully:', result);
////                return result; // return the backend response
////              } catch (error) {
////                console.error('Error uploading image:', error);
////                throw error; // Propagate the error
////              }
//              
//            };
//            img.src = backend_url + 's3download/' + image.task_image.id;
            
            
        },
        toggle_modal(modal_id = 'edit-description') {
            modal_id = modal_id.constructor.name === 'MouseEvent' ? 'edit-description' : modal_id;
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        closeZoomModal() {
            this.$root.$emit('bv::toggle::modal', 'zoom');
        },
        clone_image( image ) {
            if ( this.cloned_ids.includes( image.id ) ) { return; }
            return image;
        },
        remove_image( image_id ) {
            for ( var i = 0; i < this.right_column.length; i++ ) {
                if ( this.right_column[ i ].id === image_id ) { break; }
            }
            this.right_column.splice( i, 1 );
        },
        print_report() {
            var options = {
                payload        : this.right_column, 
                codice_pratica : this.codice_pratica,
                params         : {},
            };
            this.$store.dispatch('photoeditor/reset');
            this.$bvToast.show('gallery-toast');
            setTimeout( () => {
                this.$store.dispatch( 'photoeditor/print_report', options );
            }, 1000);
        },
        loadReport() {
            // Se hanno scritto qualcosa presentare un warning??
            this.$root.$emit('bv::toggle::modal', 'load-report');
        },
        saveReport() {
            if (this.right_column.length == 0) {
                this.$bvModal.msgBoxOk('Report vuoto!');
                return;
            }
            // Se non sono in modifica svuoto il nome del report
            if (!this.reportData.id) this.reportData.name = '';
            
            this.$root.$emit('bv::toggle::modal', 'save-report');
        },
        closeLoadReport() {
            this.$root.$emit('bv::toggle::modal', 'load-report');
        },
        closeSaveReport() {
            this.$root.$emit('bv::toggle::modal', 'save-report');
        },
        doLoadReport(el) {
            this.reportData = el;
            this.right_column = this.reportData.content;
            this.$root.$emit('bv::toggle::modal', 'load-report');
        },
        doSaveReport() {
            // Arrichisco i dati
            this.reportData.content = this.right_column;
            this.reportData.codice_pratica =  this.codice_pratica;
            
            if (this.reportData.name == '') {
                this.$bvModal.msgBoxOk('Inserire un nome per il report');
                return;
            }
            
            const options = {
                    payload        : this.reportData, 
            };
            
            // Scelgo l'operazione
            var action = this.reportData.id ? 'update' : 'create';
            // Save
            this.$store.dispatch(`pratica_reports/${action}`, options );
        },
        fill_right_column() {
            var images = this.images;
            for ( var i = 0; i < images.length; i++ ) {
                if ( this.cloned_ids.includes( images[ i ].id ) ) { continue; }
                var right_column = JSON.parse( JSON.stringify( this.right_column ) );
                right_column.push( JSON.parse( JSON.stringify( images[ i ] ) ) );
                this.right_column = right_column;
            }
        },
        empty_right_column() {
            this.right_column = [];
        },
    },
    data() {
        return {
            ed_record    : {},
            ed_key       : 0,
            ed_schema    : null,
            right_column : [],
            currentFolderLevel : 1,
            parentFodler : null,
            selectFolder : 0,
            sorting : 'name-up',
            foldersCache : [],
            zoomImage : {
                name : '',
                task_image: {
                    id : null
                },
            },
            report : {
                name : '',
                overwrite : false,
            },
//            reportList : [
//                {id : 1,  label : 'Test report', content : [] },
//                {id : 2,  label : 'Test report 2', content : [] },
//            ],
            reportData : {
                id : false,
                name : '',
                content : {},
            }
        }
    },
};

</script>

<template>
<div class="editor-container">
    <div v-if="images.length > 0">
        <div  class="toolbar">
            <div class="d-flex align-items-center left-toolbar">
                <div class="add-remove">
                    <b-button :disabled="images.length === right_column.length" variant="outline-success" size="sm" @click="fill_right_column">Aggiungi tutte</b-button>
                    <b-button :disabled="right_column.length === 0" variant="outline-danger" size="sm" @click="empty_right_column" style="margin-left: 20px;">Rimuovi tutte</b-button>
                </div>
                <div class="folder">
                    Cartella: <b-form-select v-model="selectFolder" :options="folders" value-field="id" text-field="name"></b-form-select>
                </div>
                <div class="sort ml-auto">
                    <span>Ordina per:</span>
                    <b-dropdown  class="m-md-2" size="sm" variant="outline-secondary">
                      <template #button-content>
                        <span class="fix-align">{{getSortingText()}} <b-icon :icon="getSortingOrder()" font-scale="0.80"></b-icon></span>
                      </template>
                        <b-dropdown-item :active="sorting == 'data-up'" @click="setSorting('data-up')"><span class="fix-align">Data Upload <b-icon-arrow-up font-scale="0.80"></b-icon-arrow-up></span></b-dropdown-item>
                        <b-dropdown-item :active="sorting == 'data-down'" @click="setSorting('data-down')"><span class="fix-align">Data Upload <b-icon-arrow-down font-scale="0.80"></b-icon-arrow-down></span></b-dropdown-item>
                        <b-dropdown-item :active="sorting == 'name-up'" @click="setSorting('name-up')"><span class="fix-align">Nome file <b-icon-arrow-up font-scale="0.80"></b-icon-arrow-up></span></b-dropdown-item>
                        <b-dropdown-item :active="sorting == 'name-down'" @click="setSorting('name-down')"><span class="fix-align">Nome file <b-icon-arrow-down font-scale="0.80"></b-icon-arrow-down></span></b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div class="d-flex align-items-center right-toolbar">
                <b-button variant="outline-secondary" size="sm" @click="loadReport"><b-icon-file-earmark></b-icon-file-earmark> Carica report</b-button>
                <b-button variant="outline-secondary" size="sm" @click="saveReport"><b-icon-save></b-icon-save> Salva report</b-button>
                <b-button :disabled="gallery_loader === true || right_column.length === 0" variant="outline-info" size="sm" @click="print_report"><b-icon-tools></b-icon-tools> Stampa report</b-button>
            </div>
        </div>
        <div class="editor-panel">
            <div class="left-panel">
                <h4 style="color: grey" class="text-center">Immagini della cartella Foto</h4>
                <div class="photo-container">
                    <draggable
                        :list  = "images"
                        :group = "{ name: 'people', pull: 'clone', put: false }"
                        :clone = "clone_image"
                        :sort  = "false"
                        class = "photo-list"
                    >
                        <div v-for="(element, index) in filterImages( images )" :key="index">
                            <card-left
                                :element          = "element"
                                 @edit-description = "edit_description"
                                 @zoom = "zoom"
                                 @rotate = "rotate"
                            >
                            </card-left>
                        </div>
                    </draggable>
                </div>
            </div>
            <div  class="bg-dark right-panel" style="padding: 7px; padding-top: 7px;" >
                <h4 style="color: white" class="text-center">{{getRepportName}}</h4>
                <column-right
                    :items         = "right_column"
                     @remove-image  = "remove_image"
                >
                </column-right>
            </div>
        </div>
    </div>

    <!-- loader -->
    <div v-else-if="store.loading" class="text-center">
        <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
        <span class="sr-only">Loading...</span>
        </div>
    </div>

    <!-- error message -->
    <div v-else-if="store.error === true" class="text-center">
        <b-alert variant="danger">{{ store.obj.message }}</b-alert>
    </div>

    <!-- modal edit_description -->
    <b-modal
        id          = "edit-description"
        title       = "Modifica Immagine"
        @hide       = "reset_ed"
        button-size = "sm"
    >
        <bnvform
            :key       = "ed_key"
            name       = "folder"
            :schema    = "ed_schema"
            :options   = "{}"
            :record    = "ed_record"
            :submit    = "false"
            :inline    = "true"
            ref        = "edit_description"
            layout     = "single"
            @submitted = "save_ed"
        >
        </bnvform>
        <template v-slot:modal-footer="{ ok, cancel, hide }">
            <b-row>
                <b-col>
                    <b-button size="sm" variant="success" @click="submit_ed">
                    Salva
                    </b-button>
                </b-col>
                <b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                    Chiudi
                    </b-button>
                </b-col>
            </b-row>
        </template>
    </b-modal>

    <!-- zoom -->
    <b-modal
        id          = "zoom"
        :title       = "zoomImage.name"
        @hide       = "closeZoomModal"
        size="xl"
        button-size = "sm"
    >
    <div class="zoom-container">
        <b-img center fluid :src="backend_url + 's3download/' + zoomImage.task_image.id" style="max-height: 600px;;"></b-img>
    </div>
    <template v-slot:modal-footer="{ ok  }">
        <b-row>
            <b-col>
                <b-button size="sm" variant="success" @click="closeZoomModal">
                OK
                </b-button>
            </b-col>
        </b-row>
    </template>
</b-modal>

<!-- save report -->
<b-modal
    id          = "save-report"
    title       = "Salva report"
    @hide       = "closeSaveReport"
    button-size = "sm"
    >
    <div class="save-report-container">
        <b-form-input v-model="reportData.name" placeholder="Nome report"></b-form-input>
        <!-- b-form-checkbox
            v-model="reportData.overwrite"
            value="true"
            unchecked-value="false"
            style="margin: 10px 0"
          >
        Sovrascrivere esistente
        </b-form-checkbox -->
    </div>
    <template v-slot:modal-footer="{ ok, cancel, hide }">
        <b-row>
            <b-col>
                <b-button size="sm" variant="success" @click="doSaveReport">
                Salva
                </b-button>
            </b-col>
            <b-col>
                <b-button size="sm" variant="outline-secondary" @click="closeSaveReport()">
                Annulla
                </b-button>
            </b-col>
        </b-row>
    </template>
</b-modal>


<!-- load report -->
<b-modal
    id          = "load-report"
    title       = "Carica report"
    @hide       = "closeLoadReport"
    button-size = "sm"
    >
    <div class="save-report-container">
        <h6>Seleziona un report da caricare</h6>
        <div class="report-list">
            <div v-for="(element, index) in reportList" :key="index" class="report-entry">
                    <div class="name" @click="doLoadReport(element)">{{element.name}}</div>
            </div>
            <div v-if="reportList.length == 0" class="alert alert-info" role="alert">Nessun report disponibile</div>
        </div>
    </div>
    <template v-slot:modal-footer="{ ok  }">
        <b-row>
            <b-col>
                <b-button size="sm" variant="success" @click="closeLoadReport()">
                Chiudi
                </b-button>
            </b-col>
        </b-row>
    </template>
</b-modal>


    <!-- gallery toast -->
    <b-toast
        no-auto-hide
        id       = "gallery-toast"
        :title   = "gallery_message.class"
        :variant = "gallery_variant"
        :solid   = "true"
        toaster  = "b-toaster-bottom-right"
        >
        <span v-if="gallery_message.hasOwnProperty('url') === false">{{ gallery_message.action }}</span><a v-if="gallery_message.hasOwnProperty('url')" :href="gallery_message.url" target="_blank">{{ gallery_message.file_name }}</a>
        <b-spinner v-if="gallery_loader" variant="primary" label="Spinning" style="float: right;" small></b-spinner>
    </b-toast>
</div>

</template>
<style>
.photo-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
.toolbar {
    display: flex ;
    justify-content: space-between;
}
.right-toolbar {
    gap:10px;
}
.editor-panel {
    display: flex;
    justify-content: space-between;
}
.left-panel{
    max-height: 100vh;
    overflow: scroll;
    width: 65%;
}
.sort {
    padding-left: 20px;
}
.folder {
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 400px;
    width: 400px;
    margin-left:20px;
}
.folder >  select {
}
.fix-align {
    display: flex ;
    align-items: baseline;
    float: left;
    gap: 5px;
}
.report-list {
    max-height: 300px;
    overflow: scroll;
}
.report-list .name {
    width: 100%;
    padding: 8px 4px;
}
.report-list .name:hover {
    background-color: #bbb;
    cursor: pointer;
}
</style>


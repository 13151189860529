/**
 * Helper di utilità per gestione delle email
 */

import axios from 'axios';
import { authHeader } from '@/helpers';
const placeholder_manager = require('@/helpers/placeholder_manager');

const config  = require('@/config.js')[ process.env.NODE_ENV || 'development' ];

export default class emailmanager {

    /**
     * Recupera l'elenco dei placeholder dal backend
     */
    static async placeholders() {
        try {
//            const url = config.backend_url + 'data/placeholders';
//            const options = { headers: authHeader() };
//            const response = await axios.get( url, options );
//            
//            return response.data;
            return placeholder_manager.get_placeholders();
        } catch (error) {
            console.log(error);
        }
        
        return [];
    }
    
    /**
     * Fa validare un messaggio al backend
     */
    static async validate(codice_pratica, messaggio) {
        try {
            const url = config.backend_url + 'model/reminder/validate';
            const options = { headers: authHeader() };
            const data = {
                    id : codice_pratica,
                    template : messaggio
            };
            const response = await axios.post( url, data, options );
            return response.data;
        } catch (error) {
            console.log(error);
            return {is_valid: false, error : error.response.data.message != undefined ?  error.response.data.message : error.message };
        }
        
    }

}


////////////////////
import axios from 'axios';
import { authHeader } from '@/helpers';

import base_module from '@/vue-model-mirror/stores/base.module';
const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ];

var options = {
    model_name    : 'data',
    send_alert    : false,
    default_order : 'nome ASC',
};

var base = new base_module( options );
const state = {
        ...base.state,
        placeholders : []
}

const actions = {
        ...base.actions,
        getPlaceholders( { commit }, options ) {
            var url = config.backend_url + 'data/placeholders';
            var options = { headers: authHeader() };
            axios.get( url, options )
                .then( response => {
                    commit('success_placeholders', response.data);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
}

const mutations = {
        ...base.mutations,
        success_placeholders( state, items ) {
            state.placeholders    = items;
        },
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
};


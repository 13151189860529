<script>

import components   from '../bnvlist/components';
import draggable    from 'vuedraggable';
import vmmPaginator from '../components/paginator';

const { filters } = require('../bnvlist/filters');

export default {
    name: 'bnvcards',
    props: [
        'schema',
        'storename',
        'fields',
        'primarykey',
        'skip',
        'qryoptions',
        'details',
        'detailsclass',
        'detailsaction',
        'edit',
        'destroy',
        'editaction',
        'paginate',
        'link',
        'rowclass',
        'linkfunction',
        'initialfilter',
        'formpath',
        'size',
        'tableattributes',
        'detailscmp',
        'draggable',
    ],
    components: { 'detail-card': components.detail_card, vmmPaginator, draggable },
    watch: {
        qry_executed(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`${this.schema}/executed`, false)
            }
        },
        created( new_value ) {
            if ( !new_value ) { return; }
            this.$store.dispatch( `${ this.schema }/get_all`, { force_reload: true, options: { include: true } } );
        }
    },
    computed: {
        store () {
            return this.$store.state[ this.schema ];
        },
        created() {
            return this.$store.state[ this.schema ].created;
        },
        items() {
            return this.$store.state[ this.schema ].items;
        },
        qry_executed () {
            return this.$store.state[ this.schema ].executed;
        },
        total_rows () {
            return this.$store.state[ this.schema ].tot;
        },
        order: {
            get: function() {
                return this.$store.state[ this.schema ].order;
            },
            set: function(value) {
                this.$store.dispatch(`${this.schema}/set_order`, value);
            }
        },
        page: {
            get: function() {
                return this.$store.state[ this.schema ].page;
            },
        },
        filter: {
            set: function(filters) {
                this.add_qry_filters( filters );
                this.load_rows( { options: this.get_qry_filters() } );
            },
            get: function() {
                return this.get_qry_filters();
            }
        },
        rows_per_page () {
            return this.$store.state[ this.schema ].rows_per_page;
        },
    },
    created () {
        this.filters = filters;
        if ( this.initialfilter ) {
            this.add_qry_filters( this.initialfilter );
        }
        this._set_filtered_fields();
        this.load_rows( { params: this.get_qry_filters() } );
    },
    methods: {
        convert_boolean      : (val) => filters.convert_boolean(val),
        count_items          : (val) => filters.count_items(val),
        format_date          : (val) => filters.format_date(val),
        format_datetime      : (val) => filters.format_datetime(val),
        format_date_extended : (val) => filters.format_date_extended(val),
        array_to_rows        : (val) => filters.array_to_rows(val),

        load_rows : function (options) {
            this.$store.dispatch(this.schema + '/get_all', options);
        },
        add_qry_filters: function( filters ) {                                                             // public
            Object.keys( filters ).map( x => { this.qryoptions[ x ] = filters[ x ] });
        },
        get_qry_filters : function( params = {} ) {                                                        // public
            var conf = this.qryoptions;
            Object.keys( params ).map( x => { conf[ x ] = params[ x ] } );
            if ( this.paginate === true ) {
                conf.paginate = true;
            }
            return conf;
        },
        go_to_link: function( row ) {
            if ( this.linkfunction ) {
                this.linkfunction( row.item[ row.field.url ], row.item )
                    .then( () => { this.load_rows( { options: this.get_qry_filters() } ); } );
            }
            this.$router.push( row.item[ this.link ] );
        },
        _set_filtered_fields() { // si occupa di distribuire i campi tra colonne e details
            let column_field;
            for ( var i = 0; i < this.fields.length; i++ ) {
                column_field = this._get_column_field( this.fields[ i ] );
                if ( column_field._type === 'detail' ) {
                    this.elements_details.push( column_field );
                    continue;
                } else if ( column_field.hasOwnProperty('component') ) {
                    //this.component_formatted.push( column_field );
                    column_field._type = 'component';
                } else {
                    //this.standard_fields.push( column_field )
                    column_field._type = 'standard';
                }
                this.elements.push( column_field );
            }
        },
        _get_column_field( field ) {
            var column = Object.assign( {}, field );
            if ( field.hasOwnProperty( 'class' ) && Array.isArray(field.class) ) {
                column.class = [ field.class[0], field.class[1] ].join(' ');
            }
            return column;
        },
        row_selected( rows ) {
            this.$emit('row-selected', rows );
        },
        emit_event( data ) {
            var { event_name, ...options_obj } = data;
            this.$emit(event_name, options_obj );
        },
        change_page( value ) {
            if (!value) { return; }
            this.$store.dispatch(`${this.schema}/change_page`, value);
        },
        get_field_properties( field ) {
            if ( !field.size ) { return; }
            // allarga al massimo
            if ( field.size === '>' ) { return { class: 'flex-grow-1' } }
            // restringi al minimo
            if ( field.size === '<' ) { return { class: 'flex-grow-0' } }
            // larghezza fissa in colonne di bootstrap
            var match_cols = field.size.match(/^cols-(\d+)$/);
            if ( match_cols ) { return { cols: match_cols[1] } }
            var match_cols = field.size.match(/^(\d+%)$/);
            if ( match_cols ) { return { style: `width: ${ match_cols[1] };` } }
        },
        collapse( id ) {
            this.$root.$emit('bv::toggle::collapse', `card-collapse-${ id }`)
        },
        get_card_style() {
            return this.linkfunction ? { 'cursor': 'pointer' } : {};
        },
        external_function( item ) {
            if ( !this.linkfunction ) { return; }
            this.linkfunction( item );
        },
        get_item_value( data, key ) {
            var tokens = key.split('.');
            var value = data;
            for ( var i = 0; i < tokens.length; i++ ) {
                if ( !value || value.hasOwnProperty( tokens[ i ] ) === false ) { console.error('key non valida per data', { key, data }); }
                value = value[ tokens[ i ] ];
            }
            return value;
        },
        handleRightClick( event, row ) {
            event.preventDefault();
            this.$emit('right-click', { row, event } );
            return false;
        },
        change_column( ev ) {
            this.$emit( 'moved', ev );
        },
    },
    data () {
        return {
            detail_fields       : [],
            filtered_fields     : [],
            standard_fields     : [], // campi non formattati 
            html_formatted      : [], // campi formattati con html
            component_formatted : [], // campi formattati con un component
            elements            : [], // tutti i campi della card
            elements_details    : [], // tutti i campi dei dettagli
            numClicks : 0,
        }
    },
};

</script>

<template>

    <div>

        <!-- paginator -->
        <div class="overflow-auto" v-if="paginate === true">
            <vmm-paginator
                v-if           = "items && items.length > 0"
                :page          = "page"
                :total_rows    = "total_rows"
                :rows_per_page = "rows_per_page"
                schema         = "company"
                @change        = "change_page"
            />
        </div>

        <!-- cards -->

        <div v-if="store.loading" class="text-center" variant="primary">
             <b-spinner label="Loading..."></b-spinner>
        </div>

        <div v-else-if="store.loading === false">
            <template v-if="draggable">
                <draggable
                    :list   = "items"
                    @change = "change_column"
                    group   = "people"
                    >
                    <div
                        class  = "px-2 py-1 border mb-sm list-element bnvcard"
                        v-for  = "item in items"
                        :key   = "item.codice"
                        :style = "get_card_style()"
                        @click = "external_function( item )"
                        @contextmenu.prevent="handleRightClick($event, item)"
                        >
                        <b-row align-v="center" @click="collapse( item.codice )">
                            <template v-for="field in elements">
                                <b-col v-if="field._type === 'standard'" v-bind="get_field_properties( field )" :key="'stnd_field_' + field.key">
                                    {{ get_item_value( item, field.key ) }}
                                </b-col>
                                <b-col v-else-if="field._type === 'component'" :key="field.name"  v-bind="get_field_properties( field )">
                                    <component
                                        v-bind:key    = "field.name"
                                        :name         = "field.name"
                                        :is           = "field.component"
                                        :item         = "item"
                                        @genericevent = "emit_event"
                                    ></component>
                                </b-col>
                            </template>
                            <b-col v-if="edit" class="text-right flex-grow-0" style="font-size: 1.4rem; cursor: pointer" @click="row_selected( item )">
                               <i class="fa fa-edit text-dark" />
                            </b-col>
                            <b-col v-if="destroy" class="text-right flex-grow-0">
                               <i class="fa fa-times text-muted" />
                            </b-col>
                        </b-row>
                        <b-collapse :id="'card-collapse-' + item.codice" v-if="details">
                            <component
                                :is           = "detailscmp"
                                :item         = "item"
                                :fields       = "elements_details"
                                @genericevent = "emit_event"
                            ></component>
                        </b-collapse>
                    </div>
                </draggable>
            </template>
            <template v-else-if="!draggable">
                <div
                    class  = "px-2 py-1 border mb-sm list-element bnvcard"
                    v-for  = "item in items"
                    :key   = "item.codice"
                    :style = "get_card_style()"
                    @click = "external_function( item )"
                    >
                    <b-row align-v="center" @click="collapse( item.codice )" style="width: 100%">
                        <template v-for="field in elements">
                            <b-col v-if="field._type === 'standard'" v-bind="get_field_properties( field )" :key="'stnd_field_' + field.key">
                                {{ get_item_value( item, field.key ) }}
                            </b-col>
                            <b-col v-else-if="field._type === 'component'" :key="field.name"  v-bind="get_field_properties( field )">
                                <component
                                    v-bind:key    = "field.name"
                                    :name         = "field.name"
                                    :is           = "field.component"
                                    :item         = "item"
                                    @genericevent = "emit_event"
                                ></component>
                            </b-col>
                        </template>
                        <b-col v-if="edit" class="text-right flex-grow-0" style="font-size: 1.4rem; cursor: pointer" @click="row_selected( item )">
                           <i class="fa fa-edit text-dark" />
                        </b-col>
                        <b-col v-if="destroy" class="text-right flex-grow-0">
                           <i class="fa fa-times text-muted" />
                        </b-col>
                    </b-row>
                    <b-collapse :id="'card-collapse-' + item.codice" v-if="details">
                        <component
                            :is           = "detailscmp"
                            :item         = "item"
                            :fields       = "elements_details"
                            @genericevent = "emit_event"
                        ></component>
                    </b-collapse>
                </div>
            </template>
        </div>

        <!-- paginator -->
        <div class="overflow-auto" v-if="paginate === true" style="">
            <vmm-paginator
                v-if           = "items && items.length > 0"
                :page          = "page"
                :total_rows    = "total_rows"
                :rows_per_page = "rows_per_page"
                schema         = "company"
                @change        = "change_page"
            />
        </div>
    
    </div>

</template>

<style>
    .read {
        font-style: italic;
        opacity: 0.5;
    }
.bnvcard {
    background-color: #F5F5F5;
    /* cursor: pointer; */
    border-radius: 10px;
    padding: 0 !important;
   margin: 0;
}

.bnvcard:hover {
    background-color: #F0F0F0;
}
</style>

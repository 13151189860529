<script>

import { mapActions, mapState/*, mapGetters*/ } from 'vuex';
const nprogress = require('nprogress');

import tabs          from './tabs';
import diary         from './tabs/diary';
import simple_search from '../pratica/simple-search';



export default {
    name: 'pratica-page',
    props: [ '' ],
    components : { diary },
    mounted () {
        this.$nextTick(function () {
            nprogress.done(true);
        })
    },
    computed : {
        ...mapState('pratica', {
            loading : state => state.loading_by_id,
        }),
//      ...mapGetters( 'store', [ 'attribute' ] ),
        tabs() {
            if ( this.$store.getters['authentication/customer_ids'].includes( 5 ) ) {
                return [
                    { key: 'dati', label: 'Dati Pratica', component: tabs.data, store: 'pratica' },
                    { key: 'assegnazioni', label: 'Assegnazioni', component: tabs.assegnazioni, store: 'pratica' },
                    { key: 'files', label: 'Files', component: tabs.files, store: 'pratica' },
                    { key: 'photoeditor', label: 'Editor Fotografico', component: tabs.photoeditor, store: 'pratica' },
                    { key: 'parcel', label: 'Parcelle', component: tabs.parcel, store: 'pratica_parcel' },
                ];
            }
            return [
                { key: 'dati', label: 'Dati Pratica', component: tabs.data, store: 'pratica' },
                { key: 'assegnazioni', label: 'Assegnazioni', component: tabs.assegnazioni, store: 'pratica' },
                { key: 'files', label: 'Files', component: tabs.files, store: 'pratica' },
                { key: 'photoeditor', label: 'Editor Fotografico', component: tabs.photoeditor, store: 'pratica' },
                { key: 'sollecito', label: 'Sollecitatore', component: tabs.sollecito, store: 'reminder' },
                { key: 'parcel', label: 'Parcelle', component: tabs.parcel, store: 'pratica_parcel' },
            ];
        }
    },
    watch: {
    },
    methods : {
        back_to_list() {
            this.$router.push( '/list/pratica' );
        },
        ...mapActions( 'pratica', { load_pratica: 'get_by_id' } ),
    },
    created() {
        this.codice_pratica = this.$route.params.id;
        //setTimeout( () => {
            this.load_pratica( { id: this.codice_pratica, include: true } );
            this.started = true;
        //}, 2000);
        this.$store.dispatch( 'pratica/set_read',  { codice_pratica: this.codice_pratica, read: true } );
        // facciamo caricare gia' ora la configurazione
        this.$store.dispatch( `configuration/get_all` );
    },
    data() {
        return {
            codice_pratica : null,
            started        : false,
        }
    },
}

</script>

<template>
    <div v-if="started && !loading">
        <b-row>
            <b-col><h4 style="text-align: left; padding-bottom: 14px;" class="text-secondary">GESTIONE PRATICA {{ codice_pratica }}</h4></b-col>
            <b-col><!-- <simple-search :force_reload="true"  @reload-rows="reload"/> --></b-col>
        </b-row>

        <b-card no-body>
            <b-tabs card lazy>
                <b-tab v-for="tab in tabs" :title="tab.label" v-bind:key="tab.key">
                    <b-card-text>
                        <component
                            :ref               = "'tab_' + tab.key"
                            :is                = "tab.component"
                            :schema_from_route = "tab.store"
                            :codice_pratica    = "codice_pratica"
                        ></component>
                    </b-card-text>
                </b-tab>
                <template v-slot:tabs-start>
                    <b-nav-item role="presentation" @click.prevent="back_to_list()" href="#">
                        <b-icon-x-circle-fill variant="dark" scale="1.4" font-scale = "1" ></b-icon-x-circle-fill>
                    </b-nav-item>
                </template>
            </b-tabs>
        </b-card>

        <b-card no-body style="margin-top: 20px">
            <template v-slot:header>
                <h6 class="mb-0">Diario</h6>
            </template>
            <diary
                ref               = "diary_component"
                schema_from_route = "diary"
                :codice_pratica   = "codice_pratica"
            />
        </b-card>
    </div>
</template>

<style>
</style>


////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';
import { importersService } from '@/services';

var options = {
    model_name    : 'configuration',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

const state = {
        ...base.state,
        loading             : false,
        updated             : false,
        data: {},
};
const getters = {
        ...base.getters,
        getConfigValue: ( state ) => ( section, value ) => {
            if ( state.data === null || Object.keys(state.data).length === 0 ) { return null; }
            if ( state.data[section] === null ||  Object.keys(state.data[section]).length === 0 ) { return null; }
            if ( state.data[section].config === null ||  Object.keys(state.data[section].config).length === 0 ) { return null; }
            return state.data[section].config.hasOwnProperty(value) ? state.data[section].config[value] : null;
        },
}

const actions = {
        ...base.actions,
        get_all( { commit, state, getters }, _data = { options: {} } ) {
            var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
            commit('start_request');
            state.service.get_all(getters.model_name, data.options).then( response => {
                commit('configuration_loaded', response)
            })
            .catch( error => {
                commit('error', error)
            });
        },
        update({ commit, state, getters }, data) {
            commit('start_request');
            
            // Ricostruisco l'oggetto come lo vuole il backend... sarà da rivedere se ci saranno altri parametri
            // di configurazione
            const obj = {gruppo: "solleciti", label: "Impostazioni solleciti", config: data.solleciti.config};
            state.service.update( getters.model_name, 'solleciti', obj , data.include )
            .then( obj => {
                commit('success', obj);
                commit('configuration_saved', obj);
            })
            .catch( error => {
                commit('error', error)
            });
            
        },
}

const mutations = {
        ...base.mutations,
        configuration_loaded( state, obj ) {
            state.data = {};
            // Riorganizzo i dati per avere un oggetto più comodo 
            // da utilizzare invece che un array di oggetti
            for (let i = 0; i < obj.length; i++) {
                state.data[obj[i].gruppo] = {
                        label : obj[i].label,
                        config: obj[i].config
                };
            }

            state.loading = false;
            state.executed = true;
        },
        configuration_saved( state, obj ) {
            state.loading = false;
            state.updated = true
        }
}

export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters,
};


<script>

export default {

    name: 'reminder-actions',
    props: [ 'item' ],
    data() {
        return {
            variants: {
                'admin'     : 'success',
                'poweruser' : 'warning',
                'user'      : 'light',
                'guest'     : 'light',
                'system'    : 'dark',
            },
        }
    },
    computed: {
        is_active() { return this.item.active }
    },
    methods: {
    },
    created () {},

}

</script>

<template>

    <b-badge :variant="variants[ item.role.label ]">{{ item.role.label }}</b-badge>

</template>


<script>
export default {

    name: 'reminder-actions',
    props: [ 'item' ],
    data() {
        return {
            errors: [],
        }
    },
    computed: {
        is_active() { return this.item.active },
    },
    methods: {
        send_first_email() {
            var message = 'Inviare la prima email';
            if ( !this.is_active ) { message += ' e attivare il sollecito'; }
            message += '?';
            this.$emit('genericevent', { event_name: 'send_first_email', item_id: this.item.id, message });
        },
        test_first_email() {
            var message = 'Testare la prima email?';
            this.$emit('genericevent', { event_name: 'test_first_email', item_id: this.item.id, message });
        },
        test_reminder_email() {
            var message = 'Testare le email di sollecito?';
            this.$emit('genericevent', { event_name: 'test_reminder_email', item_id: this.item.id, message });
        },
        toggle_modal() {
            this.$root.$emit('bv::toggle::modal', 'modal-errors');
        },
        clear_errors() {
            this.errors = [];
        },
        deactivate_reminder() {
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm('Disattivare il sollecito?', confirm_options)
                .then( value => {
                    if ( value === true ) {
                        this.$store.dispatch( 'reminder/update', { id: this.item.id, payload: { active: false } } );
                    }
                })
                .catch(err => {
                    console.log( err );
                });
        },
    },
    created () { },

}

</script>

<template>

    <div>

        <div class="text-center">
            <b-button style="margin-bottom: 5px; width: 80%;" :disabled="item.config.sent > 0" size="sm" variant="success" @click="send_first_email()">
                <icon name="envelope" style="margin-right: 8px;"/>Invia prima email
            </b-button><br/>
            <b-button style="margin-bottom: 5px; width: 80%;" :disabled="item.config.sent > 0" size="sm" variant="warning" @click="test_first_email()">
                <icon name="envelope" style="margin-right: 8px;"/>Test prima email
            </b-button><br/>
            <b-button :disabled="item.config.sent > 0" size="sm" variant="warning" @click="test_reminder_email()" style="margin-bottom: 5px; width: 80%;">
                <icon name="envelope" style="margin-right: 8px;"/>Test sollecito
            </b-button>
            <b-button :disabled="!item.active" size="sm" variant="danger" @click="deactivate_reminder()" style="width: 80%;">
                <icon name="ban" style="margin-right: 8px;"/>Blocca sollecito
            </b-button>
        </div>

        <!-- modal errors -->
        <b-modal
            id="modal-errors"
            title="Placeholders non valorizzati"
            @hide="clear_errors"
            >
            Alcuni placeholders dei template selezionati non sono valorizzabili sulla base dei dati della pratica.
            <div v-for="error in errors" style="margin-top: 10px;">
                <b>{{ error.label }}:</b>
                <ul>
                    <li v-for="plhd in error.placeholders">{{plhd}}</li>
                </ul>
            </div>
            Invio della email non consentito.
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="outline-danger" @click="hide('close')">
                        Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

    </div>

</template>


<script>

const nprogress = require('nprogress');

const schemas = require('@/schemaconfig');

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ];

export default {
    components: {
    },
    props: [ 'schema_from_route', ],
    async mounted () {
        this.$nextTick(function () { 
            // stop loader
            nprogress.done(true);
        })
    },
    computed: {
        dati () {
            return this.$store.state.configuration.data;
        },
        templates() {
            return this.$store.getters['email_template/template_list'](  );
        },
        update_executed() {
            return this.$store.state.configuration.updated;
        }
    },
    watch: {
      dati(new_value) {
          if (new_value) {
               this.form_data = new_value;
          }
      } ,
      update_executed(new_val) {
          if (new_val) {
              this.$bvModal.msgBoxOk('Configurazione memorizzata');
          }
      }
    },
    created () {
        this.schema_name = this.$route.params.schema || this.schema_from_route;
        this.schema = schemas[ this.schema_name ];
        this.$store.dispatch(`${this.schema_name}/get_all`, {});
        this.$store.dispatch(`email_template/get_all`, {options : { order: 'label ASC' }});
    },
    methods: {
        edit() {
            this.readonly = false;
        },
        cancel() {
            this.readonly = true;
        },
        save() {
            this.readonly = true;
            this.$store.dispatch('configuration/update', this.form_data);
        }
    },
    data () {
        return {
            readonly : true,
            form_data :{
                solleciti: {
                    config : {
                        template_prima_email : null,
                        template_sollecito : null
                    }
                }
            },
        }
    },
};

</script>

<template>

    <div>
        <h3 style="text-align: center; padding-bottom: 14px;">Configurazione</h3>
        <div class="container">
            <b-card border-variant="info" header="Solleciti" align="center">
                <b-card-text>
                    <b-row>
                        <b-col xs="12" sm="6" md="4"  class="text-left">
                            <b-form-group
                                label="Template prima email"
                                label-for="prima_email"
                              >
                                <b-form-select id="prima_email" v-model="form_data.solleciti.config.template_prima_email" :options="templates" class="mb-3" :disabled="readonly">
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Seleziona template di default --</b-form-select-option>
                                </template>
                              </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col xs="12" sm="6" md="4"  class="text-left">
                            <b-form-group
                                label="Template sollecito"
                                label-for="sollecito"
                              >
                                <b-form-select id="sollecito" v-model="form_data.solleciti.config.template_sollecito" :options="templates" class="mb-3" :disabled="readonly">
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- Seleziona template di default --</b-form-select-option>
                                </template>
                              </b-form-select>
                          </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-text>
                <div class="d-flex justify-content-start mb-3">
                    <b-button size="sm" variant="primary" v-b-tooltip.hover title="Modifica la configurazione" class="mr-1" @click="edit" v-show="readonly"><b-icon icon="pencil-fill"></b-icon> Modifica</b-button>
                    <b-button size="sm" variant="primary" v-b-tooltip.hover title="Memorizza le modifiche" class="mr-1" @click="save"  v-show="!readonly"><b-icon icon="check"></b-icon> Aggiorna</b-button>
                    <b-button size="sm" variant="outline-primary" v-b-tooltip.hover title="Annulla" @click="cancel" v-show="!readonly"><b-icon icon="x"></b-icon> Annulla</b-button>
                </div>
            </b-card>
        </div>
    </div>

</template>
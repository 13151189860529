<script>

export default {

    name: 'sollecito-document-actions',
    props: [ 'item' ],
    data() {
        return {
            errors: [],
        }
    },
    computed: {
        is_active() { return this.item.active }
    },
    methods: {
        destroy() {
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm('Eliminare il record?', confirm_options)
                .then(value => {
                    if ( value === true ) { this.$store.dispatch('sollecito_document/destroy', this.item.id); }
                })
                .catch(err => {
                    console.log( err );
                });
        },
    },
    created () {},

}

</script>

<template>

    <div>

        <div class="text-center">
            <b-button style="margin-bottom: 5px;" size="sm" variant="danger" @click="destroy()">
                <icon name="trash" style="margin-right: 8px;"/>Elimina
            </b-button><br/>
        </div>

    </div>

</template>


const emailmanager = require('@/helpers/emailmanager');

var placeholders = function( obj ) {
     var pra = obj.$store.getters['pratica/get_obj'];
     
     emailmanager.validate( pra.codice_pratica, obj.model ).then((response) => {
         if (response.data.is_valid == false) throw man.error;
     }).catch((error) => {
         throw error.response.data.message != undefined ?  error.response.data.message : error.message;
     })
     
     return true;
}

module.exports = [ placeholders ];


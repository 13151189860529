////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'pratica_reports',
    send_alert    : false,
    default_order : 'name ASC',
};

var base = new base_module( options );

const state = {
        ...base.state,
        created         : false,
        updated      : false,
};

const actions = {
        ...base.actions,
        load( { commit, state, getters, rootGetters }, _data = { options: {} } ) { // ok
            var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
            commit('start_request');
            if (!data.options) data.options = {};
            data.options.order = getters.order;

            state.service.get_all(getters.model_name, data.options)
                .then( response => {
                        commit('success_list', response)
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        create({ commit, state, getters }, data) {
            commit('start_request');
            commit('resetflags');
            state.service.create( getters.model_name, data.payload, false )
                .then( response => {
                    commit('success', response);
                    commit('created', true);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        update({ commit, state, getters }, data) {
            commit('start_request');
            commit('resetflags');
            
            // Tolgo l'id dall'oggetto per evitare problemi
            const recordId = data.payload.id;
            delete data.payload.id;
            
            state.service.update( getters.model_name, recordId, data.payload, false )
                .then( response => {
                    commit('success', response);
                    commit('updated', true);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
}

const mutations = {
        ...base.mutations,
        created( state, value ) {
            state.created = value;
        },
        updated( state, value ) {
            state.updated = value;
        },
        resetflags( state, obj ) {
            state.created = false;
            state.updated = false;
        }
}

export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters: base.getters,
};


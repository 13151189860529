//////////////////////////////

module.exports = (type) => {
    var schema = {
            id: {
                type: type.INTEGER,
                primaryKey: true,
                autoIncrement: true
            },
            name: {
                type: type.STRING(1024),
                allowNull: false,
            },
            content: {
                type: type.JSONB,
                allowNull: true,
                defaultValue: {},
            },
            codice_pratica: {
                type: type.INTEGER,
                allowNull: false,
                references: {
                    model       : 'pratiche',
                    key         : 'codice',
                    constraints : true,
                    store_name  : 'pratica',
                }
            },
    };

    return schema;
}


<script>

const { listpage }   = require('@/vue-model-mirror');
import sollecito_form from './form';

export default {
    name: 'sollecito',
    extends: listpage,

    components: {
        'sollecito-form': sollecito_form,
    },

    computed: {
        customer_id() {
            return this.$store.getters['pratica/get_obj'].customer_owner.id; // TODO e se ci fosse un custodian???
        },
        codice_pratica() {
            //return this.$store.getters['pratica/get_obj'].codice;
            let match = window.location.pathname.match( /\/form\/pratica\/(\d+)/ );
            //console.log( match[1] );
            return match[1];
        },
        task_id() {
            return this.$store.getters['pratica/get_obj'].tasks.find( x => x.task_label === 'documenti' ).id;
        },
        email_sent() { return this.$store.state.reminder.sent; },
        executed_list() {
            return this.$store.state.reminder.executed;
        },
        reminder_updated() {
            return this.$store.state.reminder.updated;
        },
        
        // Nuovi valori per poter dare una risposta all'utente sull'esito dell'azione 
        request_done() { return this.$store.state.reminder.request_done},
        request_error() { return this.$store.state.reminder.request_error},
        has_response_message() { return this.$store.state.reminder.response_message},  
        
    },

    watch: {
        has_response_message( new_value ) {
            console.log(" ==> " + new_value );
            if (new_value != '') {
                this.$bvModal.msgBoxOk(new_value);
            }
        },        
        
        
        reminder_updated( new_value ) {
            if ( !new_value || this.active_mode !== 'list' ) { return; }
            this.$store.dispatch( 'reminder/force_reload' );
            this.list_key += 1;
        },
        email_sent( new_value ) {
            if ( new_value ) {
                //console.log('email inviata');
                this.form_key += 1;
                this.make_toast( 'SOLLECITO', 'Email inviata con successo' );
            }
        },
        executed_list( new_value ) {
            if ( !new_value || this.active_mode !== 'list' ) { return; }
            //console.log('LIST CARICATA');
            if ( this.$store.state.reminder.items.length === 0 ) { return; }
            var check = this.$store.state.reminder.items[0].codice_pratica == this.codice_pratica;
            //console.log( { check } );
            if ( !check ) {
                this.loaded = false;
                this.$store.dispatch('reminder/clear');
                this.$store.dispatch('reminder/force_reload');
                this.$store.dispatch('reminder/set_customer_id', this.customer_id);
                this.$store.dispatch('reminder/set_codice_pratica', this.codice_pratica);
                this.$store.dispatch('reminder/set_task_id', this.task_id);
                this.list_key += 1;
                setTimeout( () => {
                    this.loaded = true;
                }, 1500);
            }
        },
    },

    created() {
        this.$store.dispatch('reminder/clear');
        this.$store.dispatch('reminder/force_reload');
        this.$store.dispatch('email_template/force_reload');
        this.$store.dispatch('sollecito_document_bnvtag/set_customer_id', this.customer_id);
        this.$store.dispatch('sollecito_document_bnvtag/force_reload');
        this.$store.dispatch('sollecito/clear');
        this.$store.dispatch('pratica_addresses/load_addresses');
        this.$store.dispatch('reminder/set_customer_id', this.customer_id);
        this.$store.dispatch('reminder/set_codice_pratica', this.codice_pratica);
        this.$store.dispatch('reminder/set_task_id', this.task_id);
        setTimeout( () => {
            this.loaded = true;
        }, 1500);
    },

    methods: {
        shift_to_edit( new_item_id ) {
            this.edit_item_id = new_item_id;
            this.form_key += 1;
            this.make_toast( 'SOLLECITO', 'Sollecito creato con successo' );
        },
        new_element() {
            this.active_mode = 'form';
        },
        back_to_list() {
            this.active_mode = 'list';
            this.edit_item_id = null;
            this.$refs.main_div.scrollTop = 0;
        },
        edit_item( item ) {
            this.active_mode = 'form';
            this.edit_item_id = item.id
        },
        destroy_item( item_id ) {
            console.log( 'destroy', item_id );
            this.$store.dispatch( 'reminder/destroy', item_id );
        },
        send_first_email( item_id, message ) {
            //console.log('ciaone');
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm(message, confirm_options)
                .then(value => {
                    if ( value === true ) {
                        this.$store.dispatch('reminder/send_first_email', item_id);
                        this.$store.dispatch('pratica/force_reload');
                    }
                })
                .catch(err => {
                    console.log( err );
                });
        },
        test_first_email( item_id, message ) {
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm(message, confirm_options)
                .then(value => {
                    if ( value === true ) {
                        this.$store.dispatch('reminder/test_first_email', item_id);
                    }
                })
                .catch(err => {
                    console.log( err );
                });
        },
        test_reminder_email( item_id, message ) {
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm(message, confirm_options)
                .then(value => {
                    if ( value === true ) {
                        this.$store.dispatch('reminder/test_reminder_email', item_id);
                    }
                })
                .catch(err => {
                    console.log( err );
                });
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
    },

    data () {
        return {
            active_mode  : 'list',
            edit_item_id : null,
            loaded       : false,
            form_key     : 0,
            list_key     : 0,
        }
    },

}

</script>

<template>

    <div ref="main_div">

        <div style="width: 100%; margin: auto" v-if="!active_mode || active_mode === 'list'">
            <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
            <b-row style="margin-bottom: 10px">
                <b-col v-if="add" align-self="end" class="text-right">
                    <b-button :disabled="!loaded" @click="new_element()" size="sm" variant="outline-info"><icon name="plus" style="fill: #29A746"/></b-button>
                </b-col>
            </b-row>
            <div v-if="!loaded" class="text-center">
                <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
            <bnvlist
                v-else-if            = "loaded"
                :key                 = "list_key"
                :schema              = "schema_name"
                storename            = "bnvform"
                :skip                = "skip"
                :fields              = "fields"
                :primarykey          = "primary_key"
                :qryoptions          = "qry_options"
                :details             = "details"
                :detailsclass        = "details_class"
                :detailsaction       = "details_action"
                :edit                = "edit"
                :editaction          = "edit_action"
                :paginate            = "paginate"
                :link                = "link"
                :rowclass            = "rowclass"
                :linkfunction        = "linkfunction"
                :initialfilter       = "initialfilter"
                ref                  = "listbnv"
                :size                = "size"
                :formpath            = "item_form"
                empty_string         = "Nessun sollecito inserito"
                :event_on_edit       = "true"
                @row_selected        = "row_selected"
                @right-click         = "right_click"
                @edit                = "edit_item"
                @destroy_item        = "destroy_item"
                @send_first_email    = "send_first_email"
                @test_first_email    = "test_first_email"
                @test_reminder_email = "test_reminder_email"
            ></bnvlist>
        </div>

        <div v-else-if="active_mode === 'form'" style="width: 80%; margin: auto">
            <sollecito-form
                :key              = "form_key"
                schema_from_route = "reminder"
                :id               = "edit_item_id"
                @saved            = "shift_to_edit"
                @close            = "back_to_list"
                @send_first_email = "send_first_email"
            />
        </div>

    </div>

</template>

<style>
</style>


//////////////////////////////

const moment = require('moment');

class placeholders {

    constructor( pra ) { this.pra = pra; }

    get pregs() { return this.pra.pregs; }

    get assicuratoobj() { return this.pregs.assicurato || {}; }

    get controparteobj() { return this.pregs.controparte || {}; }

    get liquidatoreobj() { return this.pregs.liquidatore || {}; }

    get ispettoratoobj() { return this.pregs.ispettorato || {}; }

    get agenziaobj() { return this.pregs.agenzia || {}; }

    get compagniaobj() { return this.pregs.compagnia || {}; }

    get incaricoobj() { return this.pregs.incarico; }

    get polizzaobj() { return this.pregs.assicurato_polizza || {}; }
    
    get clienteobj()  { return this.pregs.cliente || {}; }
    
    get extraobj() { return  this.pra.extra || {}; }

    get n_polizza() {
        return this.polizzaobj.codice || null;
    }

    get n_sinistro() {
        return this.incaricoobj.numero_sinistro || null;
    }

    get codice_pratica_interno() { return this.pra.codice; }

    get data_sinistro() {
        try {
            var date = moment( this.incaricoobj.data_sinistro, 'YYYY-MM-DD' );
            let date_label = date.format('DD/MM/YYYY');
            return date_label === 'Invalid date' ? null : date_label;
        } catch ( error ) {
            return null
        }
    }

    get data_incarico() {
        try {
            var date = moment( this.incaricoobj.data_affidamento, 'YYYY-MM-DD' );
            let date_label = date.format('DD/MM/YYYY');
            return date_label === 'Invalid date' ? null : date_label;
        } catch ( error ) {
            return null
        }
    }

//    get luogo_sinistro() {
//        return this.incaricoobj.indirizzo_luogo_sinistro || null;
//    }

//    get tipologia_incarico() {
//        return this.incaricoobj.ramo || null;
//    }

    get assicurato() {
        return this.assicuratoobj.label;
    }

    get assicurato_int() {
        if ( this.assicurato ) {
            if ( this.assicuratoobj.denominazione ) {
                return 'Spettabile ' + this.assicurato;
            } else {
                return 'Gentile ' + this.assicurato;
            }
        }
        return null;
    }

    get controparte() {
        if ( this.controparteobj && Object.keys( this.controparteobj ).length > 0 ) {
            let keys = Object.keys( this.controparteobj );
            return this.controparteobj[ keys[0] ].controparte.label;
        }
    }

    get controparte_int() {
        if ( this.controparte ) {
            let keys = Object.keys( this.controparteobj );
            if ( this.controparteobj[ keys[0] ].controparte.denominazione ) {
                return 'Spettabile ' + this.controparte;
            } else {
                return 'Gentile ' + this.controparte;
            }
        }
        return null;
    }

    get liquidatore() {
        return this.liquidatoreobj.label || null;
    }

    get liquidatore_int() {
        if ( this.liquidatore ) {
            return 'Gentile ' + this.liquidatore;
        }
        return null;
    }

    get ispettorato() {
        return this.ispettoratoobj.label || null;
    }

    get ispettorato_int() {
        if ( this.ispettorato ) {
            return 'Spettabile ' + this.ispettorato;
        }
        return null;
    }

    get agenzia() {
        return this.agenziaobj.label || null;
    }

    get agenzia_int() {
        if ( this.agenzia ) {
            return 'Spettabile ' + this.agenzia;
        }
        return null;
    }

    
    /**
     * Compagnia assicurativa 
     */
    get mandante() {
        return this.compagniaobj.label || null;
    }

    get ramo() {
        return this.incaricoobj.ramo || null;
    }

    get documenti() {
        return 'documenti';
    }

    /**
     * Nuovi placeholder
     */

    get cliente() {
        return this.clienteobj.label || null; 
    }
    
    get propietario() {
        return this.pra.customer_owner.label  || null;
    }
    
    // FIXME
    get tipo_pratica() {
        return this.pra.pregs.incarico.tipo_pratica_id || null;
    }
    
    // Stato pratica (nuovo – lavorazione – evasione – chiusa) 
    get stato_pratica() {
        return this.pra.state.label || null;
    }
    
    get tipo_danno() {
        return this.pra.pregs.incarico.tipo_danno || null
    }

    get tipo_trattazione() {
        return this.incaricoobj.tipo_trattazione || null;
    }
    
    get numero_pratica_esterno() {
        return this.incaricoobj.num_pratica_esterno || null;
    }
    
//    get luogo_sinistro_full() {
//        return this.incaricoobj.luogo_sinistro || null;
//    }
    
    get luogo_sinistro() {
        if (
                this.pra.extra.sinistro_comune != null &&
                this.incaricoobj.strada_luogo_sinistro != null &&
                this.incaricoobj.n_civ_luogo_sinistro != null &&
                this.incaricoobj.cap_luogo_sinistro != null &&
                this.incaricoobj.comune_luogo_sinistro_id != null) {
                
                let comune =this.pra.extra.sinistro_comune.nome ?  this.pra.extra.sinistro_comune.nome : '';
                let sigla = this.pra.extra.sinistro_comune.provincia ?  this.pra.extra.sinistro_comune.provincia : '';
                
                return  this.incaricoobj.strada_luogo_sinistro + ", " + this.incaricoobj.n_civ_luogo_sinistro + " - " + this.incaricoobj.cap_luogo_sinistro + " " + comune + "(" + sigla + ")"; 
            }
                
           return  this.incaricoobj.luogo_sinistro || null;
    }
    
    // (nel formato Via, Civico – CAP Città (PROV) )
    get luogo_appuntamento() {
        if (
            this.pra.extra.appuntamento_comune != null &&
            this.incaricoobj.strada_luogo_appuntamento != null &&
            this.incaricoobj.n_civ_luogo_appuntamento != null &&
            this.incaricoobj.cap_luogo_appuntamento != null &&
            this.incaricoobj.comune_luogo_appuntamento_id != null) {
            
            let comune =this.pra.extra.appuntamento_comune.nome ?  this.pra.extra.appuntamento_comune.nome : '';
            let sigla = this.pra.extra.appuntamento_comune.provincia ?  this.pra.extra.appuntamento_comune.provincia : '';
            
            return  this.incaricoobj.strada_luogo_appuntamento + ", " + this.incaricoobj.n_civ_luogo_appuntamento + " - " + this.incaricoobj.cap_luogo_appuntamento + " " + comune + "(" + sigla + ")"; 
        }
            
       return  this.incaricoobj.luogo_appuntamento || null;
    }

    get note() {
        return this.incaricoobj.note || null;
    }
    
    get data_denuncia() {
        try {
            var date = moment(  this.incaricoobj.data_denuncia, 'YYYY-MM-DD' );
            let date_label = date.format('DD/MM/YYYY');
            return date_label === 'Invalid date' ? null : date_label;
        } catch ( error ) {
            return null
        }
    }
    
    get data_primo_contatto() {
        try {
            var date = moment(  this.incaricoobj.data_primo_contatto, 'YYYY-MM-DD' );
            let date_label = date.format('DD/MM/YYYY');
            return date_label === 'Invalid date' ? null : date_label;
        } catch ( error ) {
            return null
        }
    }

    get data_sopralluogo() {
        try {
            var date = moment(  this.incaricoobj.data_sopralluogo, 'YYYY-MM-DD' );
            let date_label = date.format('DD/MM/YYYY');
            return date_label === 'Invalid date' ? null : date_label;
        } catch ( error ) {
            return null
        }
    }
    
    get data_denuncia() {
        try {
            var date = moment(  this.incaricoobj.data_denuncia, 'YYYY-MM-DD' );
            let date_label = date.format('DD/MM/YYYY');
            return date_label === 'Invalid date' ? null : date_label;
        } catch ( error ) {
            return null
        }
    }
    
    get data_videoperizia() {
        try {
            var date = moment(  this.incaricoobj.data_videoperizia, 'YYYY-MM-DD' );
            let date_label = date.format('DD/MM/YYYY');
            return date_label === 'Invalid date' ? null : date_label;
        } catch ( error ) {
            return null
        }
    }
    
    get data_invio_atto() {
        try {
            var date = moment(  this.incaricoobj.data_invio_atto, 'YYYY-MM-DD' );
            let date_label = date.format('DD/MM/YYYY');
            return date_label === 'Invalid date' ? null : date_label;
        } catch ( error ) {
            return null
        }
    }
    
    get data_evasione() {
        try {
            var date = moment(  this.incaricoobj.data_evasione, 'YYYY-MM-DD' );
            let date_label = date.format('DD/MM/YYYY');
            return date_label === 'Invalid date' ? null : date_label;
        } catch ( error ) {
            return null
        }
    }
    
    get importo_riserva() { 
        return this.formatImporto( this.incaricoobj.importo_riserva );
    }
    
    get importo_richiesto() { 
        return this.formatImporto( this.incaricoobj.importo_richiesto );
    }
    
    get tipo_chiusura() {
        return (this.extraobj.tipo_chiusura && this.extraobj.tipo_chiusura.label ) || null;
    }

    get importo_liquidato() { 
        return this.formatImporto( this.incaricoobj.importo_liquidato );
    }
    
    get importo_accertato() { 
        return this.formatImporto( this.incaricoobj.importo_accertato );
    }
    
    get telefono_assicurato() {
        return this.getFirstArrayValue(this.assicuratoobj.telefono);
    }
    
    get email_assicurato() {
        return this.getFirstArrayValue(this.assicuratoobj.email );
    }

    get telefono_danneggiato() {
        if ( this.controparteobj && Object.keys( this.controparteobj ).length > 0 ) {
            let keys = Object.keys( this.controparteobj );
            return this.getFirstArrayValue(this.controparteobj[ keys[0] ].controparte.telefono);
        }
        return null;
    }
    
    get email_danneggiato() {
        if ( this.controparteobj && Object.keys( this.controparteobj ).length > 0 ) {
            let keys = Object.keys( this.controparteobj );
            return this.getFirstArrayValue(this.controparteobj[ keys[0] ].controparte.email);
        }
        return null;
    }
    
    get liquidatore_email() {
        if ( this.liquidatoreobj && Object.keys( this.liquidatoreobj ).length > 0 ) {
            return this.getFirstArrayValue(this.liquidatoreobj.email);
        }
        return null;
    }
    
    
    get danno_presunto() { // TODO
        try {
            var value = parseFloat( this.incaricoobj.importo_riserva.replace(',', '.') ).toFixed( 2 );
            if ( isNaN( value ) ) { throw 'invalid'; }
            return value;
        } catch ( error ) {
            return null;
        }
    }

    get placeholders() {
        return [
            'assicurato',
            'assicurato_int',
            'controparte',
            'controparte_int',
            'liquidatore',
            'liquidatore_int',
            'ispettorato',
            'ispettorato_int',
            'agenzia',
            'agenzia_int',
            'n_polizza',
            'n_sinistro',
            'codice_pratica_interno',
            'data_sinistro',
            'data_incarico',
            'luogo_sinistro',
//            'tipologia_incarico',
            'mandante',
            'ramo',
            'danno_presunto',
            'documenti',
            'cliente',
            'propietario',
            'tipo_pratica',
            'stato_pratica',
            'tipo_danno',
            'tipo_trattazione',
            'numero_pratica_esterno',
//            'luogo_sinistro_full',
            'luogo_appuntamento',
            'note',
            'data_denuncia',
            'data_primo_contatto',
            'data_sopralluogo',
            'data_videoperizia',
            'data_invio_atto',
            'data_evasione',
            'importo_riserva',
            'importo_richiesto',
            'tipo_chiusura',
            'importo_liquidato',
            'importo_accertato',
            'telefono_assicurato',
            'email_assicurato',
            'telefono_danneggiato',
            'email_danneggiato',
            'liquidatore_email',
        ];
    }
    
    formatImporto( value ) {
        try {
            if ( value != null) {
                var value = parseFloat( value.replace(',', '.') ).toFixed( 2 );
            }
            if ( isNaN( value ) ) { throw 'invalid'; }
            return value;
        } catch ( error ) { console.log(error); }
        return null;
    }
    
    /**
     * Data un elenco di valori (tel. ed email) prende il "value" del primo
     */
    getFirstArrayValue( valuesList ) {
        if (Array.isArray(valuesList) && valuesList.length > 0) {
            return valuesList[0].value
        }
        return null;
    }

    get all() {
        var res = {};
        for ( var i = 0; i < this.placeholders.length; i++ ) {
            res[ this.placeholders[ i ] ] = this[ this.placeholders[ i ] ];
        }
        return res;
    }

}

class placeholder_manager {

    constructor( pra, text = null ) {
        this.plhd     = new placeholders( pra );
        this.text     = text;
        this.error    = null;
        this.error_placeholders = [];
        this.error_template = 'placeholders non valorizzati: ';
        this.subtemp_1 = 'Sinistro {{mandante}} n. {{n_sinistro}} - {{assicurato}}';
        this.subtemp_2 = ' (ns. rif. {{codice_pratica_interno}})';
    }

    static validate( pra, text = null ) {
        var obj = new placeholder_manager( pra, text );
        return obj;
    }

    static get_placeholders( pra = {} ) {
        var obj = new placeholder_manager( pra );
        return obj.plhd.placeholders.sort();
    }

    get translation() {
        if ( this.is_valid ) {
            var string = this.text;
            for ( var i = 0; i < this.involved.length; i++ ) {
                if ( this.involved_label[ i ] === 'documenti' ) { continue; } // popolato da main_manager (backend)
                var regexp = new RegExp(`${ this.involved[ i ] }`);
                string = string.replace( regexp, this.plhd[ this.involved_label[ i ] ], 'g' );
            }
            return string;
        }
        throw 'non posso tradurre un testo non valido';
    }

    get subject() {
        if ( this.is_valid ) {
            var string = this.subject_template;
            for ( var i = 0; i < this.involved_subject.length; i++ ) {
                var regexp = new RegExp(`${ this.involved_subject[ i ] }`);
                string = string.replace( regexp, this.plhd[ this.involved_label_subject[ i ] ], 'g' );
            }
            return string.replace( / {2,}/g, ' ' );
        }
        throw 'non posso tradurre un testo non valido';
    }

    get is_valid() {
        if ( !this.text ) {
            this.error = 'non posso validare se non mi passsi il testo';
            return false
        }
        var is_valid = true;
        this.error_placeholders = [];
        this.involved_label.map( x => {
            if ( !this.plhd[ x ] ) {
                is_valid = false;
                this.error_placeholders.push( x )
            }
        });
        if ( is_valid === false ) {
            this.error = this.error_template + this.error_placeholders.join(', ');
        }
        if ( is_valid ) { return this.is_valid_subject; }
        return is_valid;
    }

    get is_valid_subject() {
        var is_valid = true;
        this.error_placeholders = [];
        this.involved_label_subject.map( x => {
            if ( !this.plhd[ x ] ) {
                is_valid = false;
                this.error_placeholders.push( x )
            }
        });
        if ( is_valid === false ) {
            this.error = this.error_template + this.error_placeholders.join(', ');
        }
        return is_valid;
    }

    get involved() {
        if ( this.text ) {
            var text = this.text;
            return text.match(/{{(.*?)}}/g);
        }
        return [];
    }

    get involved_label() {
        return this.involved.map( x => {
            return x.replace(/{|}/g, '');
        });
    }

    get involved_subject() {
        if ( this.text ) {
            var text = this.subject_template;
            return text.match(/{{(.*?)}}/g);
        }
        return [];
    }

    get involved_label_subject() {
        return this.involved_subject.map( x => {
            return x.replace(/{|}/g, '');
        });
    }

    get placeholders() { return this.plhd.all; }

    get subject_template() {
        var subject = this.subtemp_1;
        if ( this.plhd.controparte ) {
            subject += ' / {{controparte}}';
        }
        subject += this.subtemp_2;
        return subject;
    }

}

module.exports = placeholder_manager;
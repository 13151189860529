<script>

export default {

    name: 'reminder-actions',
    props: [ 'item' ],
    data() {
        return {
            errors: [],
        }
    },
    computed: {
        is_active() { return this.item.active }
    },
    methods: {
    },
    created () {},

}

</script>

<template>

    <div style="text-align: center;">

        <b-button variant="warning" size="sm" @click="reset_password()">Reset Pasword</b-button>

    </div>

</template>


////////////////////

import base_module      from '@/vue-model-mirror/stores/base.module';

var options = {
    model_name    : 'email_template',
    send_alert    : false,
    default_order : 'type ASC|label ASC',
};

var base = new base_module( options );

const getters = {
    ...base.getters,
    email_body: ( state ) => ( template_id ) => {
        if (template_id == null) {
            return null;
        }
        if ( state.items === null || state.items.length === 0 ) {
            return null; 
        }
        var res = state.items.filter( x => x.id === parseInt( template_id ) );
        return res.length > 0 ? res[0].body : null;
    },
    template_list: (state) => () => {
        var ret = [];
        if (state.items) {
            for (let i = 0; i < state.items.length; i++) {
                if (state.items[i].active) {
                    ret.push( {  value: state.items[i].id, text : state.items[i].label });
                }
            }
        }
        return ret;
    }
};

const actions = {
    ...base.actions,
//    get_all( { commit, state, getters, rootGetters }, _data = { options: { } } ) { // ok
//        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
//        commit('start_request');
//        state.service.get_all(getters.model_name, data.options)
//            .then( response => {
//                commit('success_list', response)
//            })
//            .catch( error => {
//                commit('error', error)
//            });
//    },
    get_all( { commit, state, getters, rootGetters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        if (data.options) data.options = {};
        data.options.order = getters.order;

        var customers = rootGetters['statemanager/customers'].map( x => x.id );
        data.options.customer_id__in = customers.join(',');
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    /**
     * Carica un template e lo passa ad una callback (vedi sollecito/form.vue )
     */
    loadTemplate( { commit, state, getters, rootGetters }, data = { calback: function(param){  } } ) { 
        if (data.id == null) return;
        state.service.get_by_id(getters.model_name, data.id, {})
        .then( response => {
            data.callback( response.body );
        })
        .catch( error => {
            console.log(error);
        });
    },
}

export default {
    namespaced : true,
    state: base.state,
    getters,
    actions,
    mutations: base.mutations,
};


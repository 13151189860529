//////////////////////////////

module.exports = (type) => {
    var schema = {
            gruppo: {
                type: type.STRING(20),
                primaryKey: true,
            },
            label: {
                type: type.STRING(1024),
                allowNull: false,
            },
            config: {
                type: type.JSONB,
                allowNull: true,
                defaultValue: {},
            },
    };

    return schema;
}



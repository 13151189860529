<script>

import { mapState } from 'vuex';
import websocket_progress from '../layout/components/websocket_progress';

const nprogress = require('nprogress');

export default {
    name: 'importers-list',
    components: {
        'websocket-progress' : websocket_progress,
    },
    props: [],
    mounted() {
        // Spengo il loader
        this.$nextTick(function () {
            nprogress.done(true);
        })
    },
    computed: {
        importers() {
            return this.$store.state.importers_config.items;
        },
        is_otp_required() {
            return this.$store.state.websocket_progress.otp_required; 
        },
        is_completed() {
            return this.$store.state.websocket_progress.completed;
        },
        updated() {
            return this.$store.state.importers_config.updated;
        },
        action_error() {
            return this.$store.state.importers_config.error;
        },
        validOTP() {
            // Se non ha inserito nulla mi da fastidio che segnali il campo come errato
            if (this.otp.length == 0) return null;
            return /^\d{6}$/ .test( this.otp );
        },
        validUsername() {
            // Se non ha inserito nulla mi da fastidio che segnali il campo come errato
            if (this.username.length == 0) return null;
            return this.username.length > 0;
        },
        validPassword() {
            // Se non ha inserito nulla mi da fastidio che segnali il campo come errato
            if (this.password.length == 0) return null;
            return this.password.length > 0;
        },
    },
    watch: {
        is_otp_required( new_value) {
            if (new_value) {
                // Pop up del form di inserimento OTP
                this.otp = '';
                this.$root.$emit('bv::show::modal', 'modal-otp');
            }
        },
        is_completed(new_value) {
            if (new_value) {
                this.$bvModal.msgBoxOk('Esecuzione terminata')
                .then(value => {
                  this.run_importer_done();
                  this.loading = true;
                  this.$store.dispatch( 'importers_config/load' );
                  this.loading = false;
                  // Per sicurezza chiudo la dialog dell'otp
                  this.$root.$emit('bv::hide::modal', 'modal-otp');
                })
                .catch(err => {
                  console.log(err);
                })
            }
        },
        updated(new_value) {
            if (new_value) {
                this.loading = false;                
                this.$bvModal.msgBoxOk('Dati aggiornati');
                this.$store.dispatch( 'importers_config/load' );
            }
        },
        action_error(new_value) {
            if (new_value) {
                this.loading = false;                
                this.$bvModal.msgBoxOk('Errore di sistema');
                this.run_importer_done();
            }
        },
    },
    created () {
        this.$store.dispatch( 'importers_config/load' );
        this.loading = false;
    },
    methods: {
        configure( item ) {
            this.username = item.username;
            this.password = '';
            this.$root.$emit('bv::show::modal', 'modal-settings');
        },
        run(item) {
            const id = item.id;
            // Spengo tutti i tooltip... non so perche' ma mi resta visibile
            this.$root.$emit('bv::hide::tooltip');
            if (this.manual_execution_importer_id == id || item.running == 1) {
                this.$bvModal.msgBoxOk('Importer in esecuzione');
                return;
            }
            item.running = 1;
            this.manual_execution_importer_id = id;
            var data = {
                    id : id,
            };
            this.$store.dispatch( 'importers_config/run',  data );
            this.open_console = true;
            this.importer_run_terminated = false;
        },
        is_running( item ) {
            return item.id ==  this.manual_execution_importer_id;
        },
        activate(item) {
            this.confirm('Attivare <b>' + item.label +'</b>?', () => {
                this.loading = true;
                var data = {
                        id : item.id,
                        status: false
                };
                this.$store.dispatch( 'importers_config/status',  data );            
            })
        },
        suspend(item) {
            this.confirm('Sospendere <b>' + item.label +'</b>?', () => {
                this.loading = true;
                var data = {
                        id : item.id,
                        status: true
                };
                this.$store.dispatch( 'importers_config/status',  data );            
            })
        },
        confirm(text, callback) {
            // Per usare HTML nel messaggio.
            const h = this.$createElement
            const message = h('div', { domProps: { innerHTML: text } })
            
            this.$bvModal.msgBoxConfirm(message, {
                  title: 'Attenzione',
                  okTitle: 'Si',
                  cancelTitle: 'Annulla',
                  footerClass: 'p-2',
                  hideHeaderClose: false,
                  centered: true
                })
                  .then(value => {
                    callback();
                  })
                  .catch(err => {
                    console.log(err);
                  })
        },
        submit_otp_form(){
            if (!this.validOTP) {
                this.$bvModal.msgBoxOk('OTP non valido');
                return;
            }
            this.loading = true;
            var data = {
                    id : this.manual_execution_importer_id,
                    otp: this.otp,
                    reset : this.ripristinaEsecuzioneAutomatica,
            };
            this.$store.dispatch( 'importers_config/otp',  data );
            this.$root.$emit('bv::hide::modal', 'modal-otp');
        },
        submit_settings() {
            if (!validUsername) {
                this.$bvModal.msgBoxOk('Inserire un nome utente');
                return;
            }
            if (!validPassword) {
                this.$bvModal.msgBoxOk('Inserire la password');
                return;
            }
            var data = {
                    id : this.manual_execution_importer_id,
                    username: this.username,
                    password: this.password,
            };
            this.$store.dispatch( 'importers_config/settings',  data );
        },
        run_importer_done() {
            this.$store.dispatch('importers_config/setimporter_is_running', false);
            this.importer_run_terminated = true;
            this.manual_execution_importer_id = null;
        },
        clear_console() {
            this.open_console = false;
        },
        reset_form() {
            this.loading = false;
            this.otp = '';
            this.username = '';
            this.password = '';
            this.ripristinaEsecuzioneAutomatica = false;
        },
    },
    data() {
        return {
            loading: true,
            manual_execution_importer_id : null,
            open_console : false,
            importer_run_terminated : true,
            otp: '',
            username: '',
            password: '',
            ripristinaEsecuzioneAutomatica : false,
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'label', label: 'Sito' },
                { key: 'owner', label: 'Owner' },
                { key: 'type', label: 'Tipo' },
                { key: 'status', label: 'Attivo' },
                { key: 'running', label: 'In esecuzione' },
                { key: 'settings', label: '' },
                { key: 'actions', label: '' }
            ],
            items: [ ],
        };
    },
};
</script>

<template>
    <div style="width: 100%; margin: auto">
        <b-table small :fields="fields" :items="importers" responsive="sm" :busy="loading">
            <template #cell(label)="data">
                <b class="text-info">{{ data.value }}</b>
            </template>
            <template #cell(owner)="data">
                <b>{{ data.value }}</b>
             </template>
             <template #cell(type)="data">
                 <i>{{ data.value }}</i>
              </template>
             <template #cell(status)="data">
                 <b-icon icon="check" variant="success" v-show="data.value" v-b-tooltip.hover title="Attivo"></b-icon>
                 <b-icon icon="x-circle-fill" variant="danger" v-show="!data.value" v-b-tooltip.hover title="Non attivo"></></b-icon>
             </template>
             <template #cell(running)="data">
                 <b-spinner variant="success" label="In esecuzione" v-show="(data.value == 1 && data.item.type == 'Manuale') || (data.item.id ==  manual_execution_importer_id && open_console)"></b-spinner>
             </template>
             <template #cell(settings)="data">
                 <b-button size="sm" variant="outline-primary" @click="configure(data.item)">Modifica</b-button>
             </template>
             <template #cell(actions)="data">
             </template>
            <!-- A virtual column for actions-->
            <template #cell(actions)="data">
                <b-button size="sm" variant="primary" v-b-tooltip.hover title="Esegui manualmente" v-show="data.item.type == 'Manuale'" @click="run(data.item)" :disabled="data.item.running==1 || is_running(data.item)"><b-icon icon="play-circle"></b-icon></b-button>
                <b-button size="sm" variant="outline-primary" v-b-tooltip.hover title="Attiva importer" v-show="data.item.type == 'Automatico' && !data.item.status" @click="activate(data.item)"><b-icon icon="play-circle"></b-icon></b-button>
                <b-button size="sm" variant="danger" v-b-tooltip.hover title="Sospendi importer" v-show="data.item.type == 'Automatico' && data.item.status" @click="suspend(data.item)"><b-icon icon="stop-circle"></b-icon></b-button>
            </template>
          </b-table>
          <div v-if="open_console">
              <websocket-progress
                  :wspath = "'ws/importers/?code=' + manual_execution_importer_id"
                  title   = "Esecuzione importer in corso..."
                  @closed = "run_importer_done"
                  @connection_closed = "run_importer_done"
              >
              </websocket-progress>
              <div v-if="importer_run_terminated" class="d-flex justify-content-center">
                  <b-button size="sm" variant="outline-primary" @click="clear_console">Chiudi</b-button>
              </div>
          </div>
          
          <b-modal
              id="modal-otp"
              title="OTP"
              button-size="sm"
              hide-header-close
              no-close-on-esc
              no-close-on-backdrop
              hide-footer
              >
             <form @submit.prevent="submit_otp_form">
             <div class="form">
                 <b-row>
                     <b-col>
                         <label for="otp-code">Codice OTP</label>
                         <b-form-input v-model="otp" id="otp-code" placeholder="Inserire OTP ricevuto via SMS"  aria-describedby="otp-feedback"  :state="validOTP"></b-form-input>
                         <b-form-invalid-feedback id="otp-feedback">Codice non valido</b-form-invalid-feedback>
                     </b-col>
                 </b-row>
                 <b-row>
                     <b-col class="custom-footer">
                         <b-button type="submit" size="sm" variant="success">OK</b-button>
                     </b-col>
                 </b-row>
             </div>
             </form>
          </b-modal>
          
          <b-modal
              id="modal-settings"
              title="Utente e Password"
              button-size="sm"
              @hide="reset_form"
              >
             <div class="form">
                 <label for="text-username">Nome utente</label>
                 <b-form-input v-model="username" id="text-username" placeholder="Inserire un nome utente"  aria-describedby="username-feedback"  :state="validUsername"></b-form-input>
                 <b-form-invalid-feedback id="username-feedback">Nome utente non valido</b-form-invalid-feedback>
                 <label for="text-password" style="margin-top:10px;">Password</label>
                 <b-form-input v-model="password" id="text-password" placeholder="Inserire una password"  aria-describedby="password-feedback"  :state="validPassword"></b-form-input>
                 <b-form-invalid-feedback id="password-feedback">Password non valida</b-form-invalid-feedback>
             </div>
              <template v-slot:modal-footer="{ ok, cancel}">
                  <b-row>
                      <b-col>
                          <b-button size="sm" variant="success" @click="submit_settings">OK</b-button>
                      </b-col>
                      <b-col>
                          <b-button size="sm" variant="outline-secondary" @click="hide">Chiudi</b-button>
                      </b-col>
                  </b-row>
              </template>
          </b-modal>
    </div>
</template>
<style>
.custom-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
}
</style>
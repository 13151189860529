<script>

const config  = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export default {
    name: 'pratica-cardleft',
    props: [ 'element', ],
    components: {
    },
    watch: {
    },
    created() {
    },
    computed: {
        backend_url() { return config.backend_url },
    },
    methods: {
        edit_description( obj ) {
            this.$emit( 'edit-description', obj );
        },
        zoom(obj) {
            this.$emit( 'zoom', obj );
        },
        rotate(obj) {
            this.$emit( 'rotate', obj );  
        },
        formatDate(dataString) {
            const data = new Date(dataString);

            const opzioni = {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              timeZone: 'Europe/Rome' // Imposta il fuso orario italiano
            };

            return data.toLocaleString('it-IT', opzioni);
          },
    },
    data() {
        return {
        }
    },
};

</script>

<template>

   <div class="image-wrapper">
       <div class="image-tools">
         <b-button variant="outline-secondary" size="sm" @click="zoom(element)">
           <b-icon icon="search"></b-icon>
         </b-button>
         <!-- b-button variant="outline-secondary" size="sm" @click="rotate(element)">
             <b-icon icon="arrow-clockwise"></b-icon>
           </b-button -->
       </div>
       <b-img v-if="element.task_image.value.thumb_url" center thumbnail fluid :src="backend_url + element.task_image.value.thumb_url.replace(/^\//, '')"></b-img>
       <b-img v-else-if="element.task_image.value.thumb_url === null" center thumbnail fluid :src="backend_url + 'icons/no-image.png'"></b-img>
       <figcaption class="figure-caption text-center">
           <a href="#" @click="edit_description( element.task_image )">
               {{ element.task_image.value.description || 'descrizione assente' }}
           </a>
           <div class="filename">{{element.task_image.name}}</div>
           <div class="filedate">{{ formatDate(element.task_image.value.created) }}</div>
       </figcaption>
       <div v-if="element.task_image.value.thumb_url === null">
       </div>
</div>

</template>
<style>
ddiv[draggable=false] > img,  ddiv[draggable=false] > figcaption {
    opacity: 0.4;
}
.image-tools {
    position: static;
    width: 100%;
    display: flex;
    justify-content: space-between;
    opacity: 0; /* Inizialmente nascosti */
    transition: opacity 0.3s ease;
}
.image-wrapper:hover > .image-tools {
    opacity: 1; 
}
.filename, .filedate {
    font-size: 80%;
}
</style>


